/*------------- #THEME STYLES --------------*/

@import "table-of-content.scss";

@import "theme-styles/variables";
@import "theme-styles/global";
@import "theme-styles/typography";
@import "theme-styles/grid";
@import "theme-styles/helper";
@import "theme-styles/normalize";
@import "theme-styles/preloader";
@import "theme-styles/header";
@import "theme-styles/primary-menu";
@import "theme-styles/tabs";
@import "theme-styles/footer";
@import "theme-styles/page-404";
@import "theme-styles/magnific-popup";