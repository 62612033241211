
/*------------- #TYPOGRAPHY --------------*/


h1, .h1 {
  font-size: $h1-fontsize;
  font-weight: $h-font-weight;
  color: $heading-color;
  font-family: $heading-font-family;
  margin:  $h-margin;
  line-height: 1em;
  letter-spacing: $h-letter-spacing;

  a {
    color: inherit;
  }

  @media (max-width: 800px) {
    font-size: 2em;
  }

  @media (max-width: 640px) {
    font-size: 1.8em;
  }


  @media (max-width: 360px) {
    font-size: 1.5em;
  }
}

h2, .h2 {
  margin:  $h-margin;
  font-size: $h2-fontsize;
  line-height: 1.3em;
  font-weight: $h2-font-weight;
  color: $heading-color;
  font-family: $heading-font-family;
  letter-spacing: $h-letter-spacing;

   a {
    color: inherit;
  }

  @media (max-width: 800px) {
    font-size: 1.9em;
  }

  @media (max-width: 640px) {
    font-size: 1.7em;
  }

  @media (max-width: 480px) {
    font-size: 1.5em;
  }

  @media (max-width: 360px) {
    font-size: 1.3em;
  }
}

h3, .h3 {
  margin:  $h-margin;
  font-size: $h3-fontsize;
  line-height: 1.3em;
  font-weight: $h-font-weight;
  color: $heading-color;
  font-family: $heading-font-family;
  letter-spacing: $h-letter-spacing;

  a {
    color: inherit;
  }

  @media (max-width: 800px) {
    font-size: 1.8em;
  }

  @media (max-width: 640px) {
    font-size: 1.6em;
  }

  @media (max-width: 480px) {
    font-size: 1.4em;
  }

  @media (max-width: 360px) {
    font-size: 1.2em;
  }
}

h4, .h4 {
  margin:  $h-margin;
  font-size: $h4-fontsize;
  line-height: 1.3em;
  font-weight: $h-font-weight;
  color: $heading-color;
  font-family: $heading-font-family;
  letter-spacing: $h-letter-spacing;

  a {
    color: inherit;
  }

  @media (max-width: 800px) {
    font-size: 1.7em;
  }

  @media (max-width: 640px) {
    font-size: 1.5em;
  }

  @media (max-width: 480px) {
    font-size: 1.3em;
  }

  @media (max-width: 360px) {
    font-size: 1.1em;
  }
}

h5, .h5 {
  margin:  $h-margin;
  font-size: $h5-fontsize;
  line-height: 1.3em;
  font-weight: $h-font-weight;
  color: $heading-color;
  font-family: $heading-font-family;
  letter-spacing: $h-letter-spacing;

  a {
    color: inherit;
  }

  @media (max-width: 800px) {
    font-size: 1.6em;
  }

  @media (max-width: 640px) {
    font-size: 1.4em;
  }

  @media (max-width: 480px) {
    font-size: 1.2em;
  }

  @media (max-width: 360px) {
    font-size: 1em;
  }
}

h6, .h6 {
  margin:  $h-margin;
  font-size: $h6-fontsize;
  line-height: 1.3em;
  font-weight: $h-font-weight;
  color: $heading-color;
  font-family: $heading-font-family;
  letter-spacing: $h-letter-spacing;

  a {
    color: inherit;
  }

  @media (max-width: 800px) {
    font-size: 1.4em;
  }

  @media (max-width: 640px) {
    font-size: 1.2em;
  }

  @media (max-width: 480px) {
    font-size: 1em;
  }

  @media (max-width: 360px) {
    font-size: .9em;
  }

}


/*------------- #Styles for First-Letter --------------*/

.first-letter span:first-of-type {
  display: block;
  position: relative;
  float: left;
  line-height: 1;
  font-family: 'Playfair Display', serif;
  z-index: -1;
  font-weight: 900;
}

.first-letter--under-text span:first-of-type {
  margin-right: -80px;
  margin-top: -50px;
  font-size: 160px;

  @media (max-width: 768px) {
    font-size: 80px;
    margin-top: 0;
  }
}

.first-letter--before-text span:first-of-type {
  margin-right: 35px;
  margin-bottom: 30px;
  margin-top: -15px;
  font-size: 106px;

  @media (max-width: 768px) {
    font-size: 60px;
    margin-top: 0;
  }
}

/*------------- #Styles for Text Selection --------------*/

/*Selection dark*/

.selection--dark::-moz-selection{background: $heading-color; color: $body-font-color;}
.selection--dark::selection{background: $heading-color; color: $body-font-color;}

/*Selection primary*/

.selection--primary::-moz-selection{color: $white-color;}
.selection--primary::selection{color: $white-color;}

/*Selection gray*/

.selection--gray::-moz-selection{background: #e1e1e1; color: $heading-color;}
.selection--gray::selection{background: #e1e1e1; color: $heading-color;}


dl {
  margin-bottom: 24px;
}

dl dt {
  margin-bottom: 5px;
  color: #777;
  font-weight: bold;
}

dl dd {
  margin-bottom: 5px;
  margin-left: 20px;
}

abbr {
  cursor: help;
  border-color: $body-font-color;
}

hr {
  position: relative;
  display: block;
  width: 100%;
  min-height: 3px;
  margin: 5px auto;
  border: none;
  border-bottom: 2px solid $hr-color;
}