/*
Item name: primary-menu - Responsive Megamenu
Author: marcoarib - marco.arib@gmail.com
License: http://codecanyon.net/licenses
*/


/*
	CONTENTS:

	 1 - MENU CONTAINER
		- Scrollable menu
	 2 - BRAND
	 3 - MENU
		- Right alignment
		- Menu items
		- Active/hover state
	 4 - DROPDOWN
		- Dropdown items
		- Hover state
		- Dropdown left alignment
	 5 - DROPDOWN/MEGAMENU INDICATORS
	 6 - MEGAMENU
	 7 - MEGAMENU WIDTHS
		- Half width
		- Quarter width
	 8 - GRID SYSTEM
	 9 - SHOW/HIDE BUTTON (MOBILE MODE)
		- Icon
	10 - ICONS (parent menu items)
	11 - SEARCH FIELD
		- Form
		- Input
	12 - VERTICAL TABS
	13 - COLOR SCHEMES
	14 - MOBILE MODE (RESPONSIVE MODE)
		- Menu container
		- Brand
		- Show/hide button
		- Menu
		- Dropdown
		- Dropdown items
		- Dropdown/megamenu indicators
		- Megamenu
		- Search field
*/


/* 1 - MENU CONTAINER
======================================*/
.primary-menu,
.primary-menu *{
  box-sizing:border-box;
  -moz-box-sizing:border-box;
  -webkit-box-sizing:border-box;
}
.primary-menu > ul {
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  padding-left: 0;
  list-style: none;
}

.primary-menu a{
  -o-transition: color .3s linear, background .3s linear;
  -moz-transition: color .3s linear, background .3s linear;
  -webkit-transition: color .3s linear, background .3s linear;
  transition: color .3s linear, background .3s linear;
}
/* scrollable menu */
.primary-menu-menu.scrollable > .scrollable-fix{
  display: none;
}

/* 2 - BRAND
======================================*/
.primary-menu-brand{
  margin: 18px 30px 0 0;
  float: left;
  color: #666;
  text-decoration: none;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.3;
  cursor: pointer;
}

/* 3 - MENU
======================================*/
/* Right alignment */
.primary-menu-menu.primary-menu-right{
  //float: right;
}
/* Menu items */
.primary-menu-menu > li{
  display: inline-block;
  position: relative;
  padding: 20px;
  float: left;
  transition: all .3s ease;
  border-radius: 10px 10px 0 0;
  background-color: transparent;
}

.primary-menu-menu > li > a{
  position: relative;
  font-size: 16px;
  font-weight: 700;
  transition: all .3s ease;
  display: inline-block;
  line-height: 1;
}

.primary-menu-menu > li:hover > a .indicator,
.primary-menu-menu > li:hover .indicator,
.primary-menu-menu > li:hover > a,
.primary-menu-menu > li:hover,
.primary-menu-menu > li.current-menu-item a,
.primary-menu-menu > li.current-menu-item {
  color: $primary-color;
}

.primary-menu-menu > li:hover .megamenu.megamenu-with-slider {
  color: $body-font-color;
}

.primary-menu-menu > li:hover {
  background-color: #191A1F;
}

.primary-menu-menu > li > a:before {
  content: '';
  display: block;
  border-top: 2px solid;
  position: absolute;
  left: 50%;
  width: 0;
  transform: translateX(-50%);
  bottom: -12px;
  opacity: 0;
  transition: all .3s linear;
}

.primary-menu-menu > li:hover > a:before,
.primary-menu-menu > li.current-menu-item > a:before {
  opacity: 1;
  width: 100%;
}

.primary-menu-menu > li > i {
  font-size: 10px;
}

.primary-menu-responsive .primary-menu-menu > li > i {
  display: none;
}

.primary-menu-menu > li.menu-item-has-mega-menu {
  position: static;
}

.no-menu-box{
  font-size: 14px;
}

/* 4 - DROPDOWN
======================================*/
.primary-menu-menu ul.sub-menu,
.primary-menu-menu ul.sub-menu li ul.sub-menu{
  position: absolute;
  z-index: 9999;
  left: 0;
  min-width: 270px;
  top: 100%;
  background-color: #191A1F;
  padding: 10px 0 30px;
  border-radius: 0 10px 10px 10px;
  box-shadow: 0 20px 80px 0 rgba(0, 0, 0, 0.4);
  list-style: none;
  display: none;
  margin-bottom: 0;
  margin-top: 0;
}

@media (max-width: 768px) {
  .primary-menu-menu ul.sub-menu,
  .primary-menu-menu ul.sub-menu li ul.sub-menu {
	box-shadow: none;
	padding: 0;
  }
}

/* Megamenu with slider*/

.primary-menu-menu > li > .megamenu.megamenu-with-slider {
  display: block;
  padding: 40px;

  .crumina-module-slider {
    overflow: hidden;
  }
}

.primary-menu-menu > li.menu-item-has-mega-menu .megamenu ul > li,
.primary-menu-menu ul.sub-menu li {
  position: relative;
  margin-bottom: 0;
}

.primary-menu-menu ul.sub-menu a i{
  margin-left: 10px;
  opacity: 0;
  transition: all .4s ease;
  font-size: 12px;
  color: #e1e1e1;
  text-align: center;
  margin-right: 0;
  position: absolute;
  top: 50%;
  margin-top: -6px;
}

.primary-menu-menu > li.menu-item-has-mega-menu .megamenu ul > li a i {
  font-size: 24px;
  opacity: 1;
  margin-right: 15px;
  margin-left: 0;
  vertical-align: middle;
}

.primary-menu-menu ul.sub-menu li ul.sub-menu{
  left: 100%;
  top: -10px;
  border-radius: 10px;
}
/* Dropdown items */

.primary-menu-menu ul.sub-menu > li {
  &:before {
    content: '';
    display: block;
    height: 100%;
    width: 2px;
    background-color: $primary-color;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    transition: all .4s ease-out;
  }
}

.primary-menu-menu ul.sub-menu li{
  clear: both;
  width: 100%;
  border: 0 none;
  position: relative;
}

.primary-menu-menu ul.sub-menu li {
  padding: 10px 20px 10px 40px;
  position: relative;
  transition: all .4s ease-out;

  &:hover {
	span {
	  color: $primary-color;
	}

    &:before {
      opacity: 1;
    }
  }

  span {
	color: $body-font-color;
	transition: all .4s ease-out;
  }
}

.primary-menu-menu ul.sub-menu li a {
  transition: all .4s ease-out;
  display: block;
  line-height: 1;
  font-weight: 400;
}

.primary-menu-menu ul.sub-menu.sub-menu--with-icons {
  padding-bottom: 20px;

 li {
   padding: 0 40px;

   &:before {
     display: none;
   }

   a {
     display: flex;
     align-items: center;
     padding: 20px 0;
     border-bottom: 1px solid $hr-color;
   }

   & > a > span {
     margin-left: 4px;
   }

   .ericore-icon {
     width: 18px;
     height: 18px;
     margin-right: 13px;
   }

   .icon-arrow-right-line {
     margin-left: auto;
     margin-right: 0;
     width: 10px;
     height: 10px;
     opacity: 0;
     fill: $primary-color;
   }

   &:last-child a {
     border-bottom: none;
   }

   &:hover {
     .icon-arrow-right-line {
       opacity: 1;
     }

     & > a,
     & > a > span {
       color: $primary-color;
     }
   }
 }

}

@media (max-width: 640px) {
  .primary-menu-menu ul.sub-menu li a {
	padding: 10px;
  }
}

.primary-menu-menu ul.sub-menu.sub-menu-has-icons li i {
  font-size: 24px;
  opacity: 1;
  margin-right: 15px;
  margin-left: 0;
  vertical-align: middle;
  position: relative;
}

.primary-menu-menu ul.sub-menu.sub-menu-has-icons li.menu-item-has-children > a > i {
  opacity: 1;
}

.primary-menu-menu > li.menu-item-has-mega-menu .megamenu ul > li a {
  padding: 15px 0;
  position: relative;
  transition: all .4s ease-out;
  display: block;
  line-height: 1;
  border-bottom: 1px solid $hr-color;
  font-weight: 400;
}

.megamenu-bg {
  background-image: url("../img/megamenu-bg.png");
  background-position: right bottom;
  background-repeat: no-repeat;
}

.primary-menu-menu > li.menu-item-has-mega-menu .megamenu ul > li {
  &:last-child {
	a {
	  border-bottom: none;
	}
  }
}

/* Hover state (sub-menu items) */
.primary-menu-menu ul.sub-menu li:hover > a,
.primary-menu-menu > li.menu-item-has-mega-menu .megamenu ul > li:hover a {
  transition: all .3s ease;
  color: $primary-color;
}

/* Dropdown left alignment */
.primary-menu-menu ul.sub-menu.sub-menu-left {
  left: auto;
  right: 0;
}

.primary-menu-menu ul.sub-menu li ul.sub-menu.sub-menu-left {
  left: -100%;
  right: auto;
}

/* 5 - DROPDOWN/MEGAMENU INDICATORS
======================================*/
.primary-menu-menu li .indicator{
  color: rgba($primary-color, .3);
  font-family: "FontAwesome";
  font-size: 10px;
  margin-left: 4px;
  transition: inherit;
}
.primary-menu-menu li ul.sub-menu li .indicator{
  position: absolute;
  top: 13px;
  right: 20px;
  font-size: 15px;
  transition: all .3s ease;
}

/* 6 - MEGAMENU
======================================*/
.primary-menu-menu > li > .megamenu{
  width: 100%;
  left: 0;
  position: absolute;
  display: none;
  z-index: 99;
  background-color: #191A1F;
  top: 100%;
  box-shadow: 0 20px 80px 0 rgba(0, 0, 0, 0.4);
  border-radius: 10px;
}

.primary-menu-menu > li > .megamenu ul {
  list-style: none;
  margin: 0;
}

.primary-menu-menu > li > .megamenu .google.site-logo {
  margin-bottom: 15px;
}

.primary-menu-menu > li > .megamenu .megamenu-item-info .megamenu-item-info-title,
.primary-menu-menu > li > .sub-menu .megamenu-item-info .megamenu-item-info-title {
  margin-bottom: 30px;
  line-height: 1.2;
  font-size: 18px;
  text-transform: uppercase;
}

.megamenu-item-info-text {
  font-size: 14px;
  color: $icon-color;
  line-height: 1.3;
}



/* 7 - MEGAMENU WIDTHS
======================================*/
/* Half width */
.primary-menu-menu > li > .megamenu.megamenu-half-width{
  width: 50%;
  left: auto;
}
/* Quarter width */
.primary-menu-menu > li > .megamenu.megamenu-quarter-width{
  width: 25%;
  left: auto;
}

/* 8 - GRID SYSTEM
======================================*/
.primary-menu-menu > li > .megamenu .megamenu-row{
  width: 100%;
  margin-top: 15px;
}
.primary-menu-menu > li > .megamenu .megamenu-row:first-child {
  margin-top: 0;
}
.primary-menu-menu > li > .megamenu .megamenu-row:after {
  clear: both;
}
.primary-menu-menu > li > .megamenu .megamenu-row .col1,
.primary-menu-menu > li > .megamenu .megamenu-row .col2,
.primary-menu-menu > li > .megamenu .megamenu-row .col3,
.primary-menu-menu > li > .megamenu .megamenu-row .col4,
.primary-menu-menu > li > .megamenu .megamenu-row .col5,
.primary-menu-menu > li > .megamenu .megamenu-row .col6,
.primary-menu-menu > li > .megamenu .megamenu-row .col7,
.primary-menu-menu > li > .megamenu .megamenu-row .col8,
.primary-menu-menu > li > .megamenu .megamenu-row .col9,
.primary-menu-menu > li > .megamenu .megamenu-row .col10,
.primary-menu-menu > li > .megamenu .megamenu-row .col11,
.primary-menu-menu > li > .megamenu .megamenu-row .col12{
  display: block;
  min-height: 20px;
  float: left;
  padding: 40px 20px 20px;

  &:first-child {
    padding-left: 40px;
  }

  &:last-child {
    padding-right: 40px;
  }
}

.primary-menu-menu > li > .megamenu .megamenu-row .col1{
  width: 100%;
}
.primary-menu-menu > li > .megamenu .megamenu-row .col2{
  width: 50%;
}
.primary-menu-menu > li > .megamenu .megamenu-row .col3{
  width: 33.33%;
}
.primary-menu-menu > li > .megamenu .megamenu-row .col4{
  width: 25%;
}
.primary-menu-menu > li > .megamenu .megamenu-row .col5{
  width: 20%;
}
.primary-menu-menu > li > .megamenu .megamenu-row .col6{
  width: 16.6666%;
}
.primary-menu-menu > li > .megamenu .megamenu-row .col7{
  width: 14.28%;
}
.primary-menu-menu > li > .megamenu .megamenu-row .col8{
  width: 12.5%;
}
.primary-menu-menu > li > .megamenu .megamenu-row .col9{
  width: 11.11111%;
}
.primary-menu-menu > li > .megamenu .megamenu-row .col10{
  width: 10%;
}
.primary-menu-menu > li > .megamenu .megamenu-row .col11{
  width: 9.09090909%;
}
.primary-menu-menu > li > .megamenu .megamenu-row .col12{
  width: 8.333333%;
}

/* 9 - SHOW/HIDE BUTTON (MOBILE MODE)
======================================*/


.menu-icon-wrapper{
  position: relative;
  display: inline-block;
  width: 34px;
  height: 34px;
  pointer-events: none;
  transition: 0.1s;
}

.menu-icon-wrapper.scaled{
  transform: scale(0.5);
}

.menu-icon-wrapper svg{
  position: absolute;
  top: -33px;
  left: -33px;
}


.menu-icon-wrapper svg path{
  stroke: $icon-color;
  stroke-width: 30px;
  stroke-linecap: round;
  fill: transparent;
}


.menu-icon-wrapper .menu-icon-trigger{
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;
  pointer-events: auto;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
}

.menu-icon-wrapper svg {
  transform: scale(0.1);
  transform-origin: 0 0;
}

.showhide .mob-menu--title{
  position: relative;
  top: 2px;
  text-transform: uppercase;
  font-size: 18px;
  margin-right: 15px;
  font-weight: bold;
}
.primary-menu .showhide > span{
  display: inline-block;
  vertical-align: middle;
}

.primary-menu .showhide{
  cursor: pointer;
  text-align: center;
  padding: 0;
  display: none;
  text-decoration: none;
  outline: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
}


/* 10 - ICONS (parent menu items)
======================================*/
.primary-menu-menu > li > a > i{
  margin: 0 5px 0 0;
  font-size: 18px;
  float: left;
  line-height: 20px;
  font-family: 'FontAwesome';
}

/* 11 - SEARCH FIELD
======================================*/
/* Form */
.primary-menu-menu > li.search form{
  float: left;
  padding: 22px 16px 17px;
}
/* Input */
.primary-menu-menu > li.search form input[type="text"]{
  width: 160px;
  padding: 5px 10px;
  font-family: 'Open Sans', sans-serif;
  border: none;
  background: #f0f0f0;
  border-radius: 2px;
  outline: 0;
  -o-transition: width 1s ease-out;
  -moz-transition: width 1s ease-out;
  -webkit-transition: width 1s ease-out;
  transition: width 1s ease-out;
}
.primary-menu-menu > li.search form:hover input[type="text"]{
  width: 160px;
}

/* 12 - VERTICAL TABS
======================================*/
.primary-menu-tabs{
  width: 100%;
  float: left;
}
.primary-menu-tabs-nav{
  width: 20%;
  margin: 0;
  padding: 0;
  float: left;
  list-style: none;
}
.primary-menu-tabs-nav > li > a{
  width: 100%;
  padding: 7px 16px;
  float: left;
  font-size: 13px;
  text-decoration: none;
  color: #666;
  border: solid 1px #f0f0f0;
  outline: 0;
}
.primary-menu-tabs-nav li.active a,
.primary-menu-tabs-nav li:hover a{
  background: #f0f0f0;
}
.primary-menu-tabs-content{
  width: 80%;
  min-height: 30px;
  padding: 20px;
  float: right;
  display: none;
  font-size: 13px;
  border: solid 1px #f0f0f0;
}
.primary-menu-tabs-content.active{
  display: block;
}

/* 13 - COLOR SCHEMES
======================================*/
/* blue */
.primary-menu.blue .primary-menu-menu > li.active > a,
.primary-menu.blue .primary-menu-menu > li:hover > a,
.primary-menu.blue .primary-menu-menu ul.sub-menu li:hover > a{
  color: #4A89DC;
}
/* green */
.primary-menu.green .primary-menu-menu > li.active > a,
.primary-menu.green .primary-menu-menu > li:hover > a,
.primary-menu.green .primary-menu-menu ul.sub-menu li:hover > a{
  color: #37BC9B;
}
/* red */
.primary-menu.red .primary-menu-menu > li.active > a,
.primary-menu.red .primary-menu-menu > li:hover > a,
.primary-menu.red .primary-menu-menu ul.sub-menu li:hover > a{
  color: #E9573F;
}
/* orange */
.primary-menu.orange .primary-menu-menu > li.active > a,
.primary-menu.orange .primary-menu-menu > li:hover > a,
.primary-menu.orange .primary-menu-menu ul.sub-menu li:hover > a{
  color: #ff670f;
}
/* yellow */
.primary-menu.yellow .primary-menu-menu > li.active > a,
.primary-menu.yellow .primary-menu-menu > li:hover > a,
.primary-menu.yellow .primary-menu-menu ul.sub-menu li:hover > a{
  color: #F6BB42;
}
/* purple */
.primary-menu.purple .primary-menu-menu > li.active > a,
.primary-menu.purple .primary-menu-menu > li:hover > a,
.primary-menu.purple .primary-menu-menu ul.sub-menu li:hover > a{
  color: #967ADC;
}
/* pink */
.primary-menu.pink .primary-menu-menu > li.active > a,
.primary-menu.pink .primary-menu-menu > li:hover > a,
.primary-menu.pink .primary-menu-menu ul.sub-menu li:hover > a{
  color: #D770AD;
}

/* 14 - MOBILE MODE (RESPONSIVE MODE)
======================================*/


@media (max-width: 1300px){
  .primary-menu-menu > li > a {
    font-size: 14px;
  }

  .primary-menu-menu > li {
    padding: 20px 10px;
  }
}

@media (max-width: 1023px){
  /* Responsive menu container */
  .primary-menu-responsive.primary-menu{
	overflow: visible;
  }

  .primary-menu .showhide {
    display: block;
  }

  .primary-menu .primary-menu-menu {
    display: none;
  }

  .primary-menu-responsive.primary-menu .crumina-event-item{
    padding: 15px;
  }

  /* Brand */
  .primary-menu-responsive .primary-menu-brand{
	margin: 8px 30px 10px 20px;
	font-size: 20px;
	line-height: 1.55;
  }

  .primary-menu-responsive .primary-menu-menu > li > .megamenu .megamenu-item-info .megamenu-item-info-title {
    margin-bottom: 5px;
    font-size: 16px;
  }

  .primary-menu-responsive .primary-menu-menu > li > .megamenu .megamenu-row .col1,
  .primary-menu-responsive .primary-menu-menu > li > .megamenu .megamenu-row .col2,
  .primary-menu-responsive .primary-menu-menu > li > .megamenu .megamenu-row .col3,
  .primary-menu-responsive .primary-menu-menu > li > .megamenu .megamenu-row .col4,
  .primary-menu-responsive .primary-menu-menu > li > .megamenu .megamenu-row .col5,
  .primary-menu-responsive .primary-menu-menu > li > .megamenu .megamenu-row .col6,
  .primary-menu-responsive .primary-menu-menu > li > .megamenu .megamenu-row .col7,
  .primary-menu-responsive .primary-menu-menu > li > .megamenu .megamenu-row .col8,
  .primary-menu-responsive .primary-menu-menu > li > .megamenu .megamenu-row .col9,
  .primary-menu-responsive .primary-menu-menu > li > .megamenu .megamenu-row .col10,
  .primary-menu-responsive .primary-menu-menu > li > .megamenu .megamenu-row .col11,
  .primary-menu-responsive .primary-menu-menu > li > .megamenu .megamenu-row .col12 {
    padding: 5px 20px;
    margin-top: 0;
  }

  /* Show/hide button */


  /* Menu */
  .primary-menu-responsive .primary-menu-menu{
	width: 100%;
    padding: 0;
    border-radius: 0 0 10px 10px;
    background-color: $chart-bg;
    box-shadow: 15px 0 25px 0 rgba(0, 0, 0, 0.3);
  }

  .primary-menu-responsive .primary-menu-menu ul.sub-menu {
	top: 0;
  }

  /* scrollable menu*/
  .primary-menu-responsive .primary-menu-menu.scrollable {
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
	position: absolute;
    top: calc(100% + 15px);
    left: 0;
    max-height: 60vh !important;
  }

  .header.header-has-topbar .primary-menu-responsive .primary-menu-menu.scrollable {
    top: 100%;
  }

  .primary-menu-responsive .primary-menu-menu.scrollable > .scrollable-fix{
	display: block;
  }

  /* Menu items */
  .primary-menu-responsive .primary-menu-menu > li{
	width: 100%;
	display: block;
	position: relative;
    border-radius: 0;
  }
  .primary-menu-responsive .primary-menu-menu > li > a{
	width: 100%;
	padding: 18px 20px;
	display: block;
    border-bottom: solid 1px rgba(255, 186, 0, .1);
    font-weight: 900;
  }

  .primary-menu-responsive .primary-menu-menu > li > a:before {
    display: none;
  }

  .primary-menu-responsive .primary-menu-menu > li:first-child > a {
    border-top: solid 1px rgba(255, 186, 0, .1);
  }

  .primary-menu-responsive .primary-menu-menu > li:nth-last-child(2) > a {
    border-bottom: none;
  }
  /* Dropdown */
  .primary-menu-responsive .primary-menu-menu ul.sub-menu,
  .primary-menu-responsive .primary-menu-menu ul.sub-menu li ul.sub-menu{
	width: 100%;
	left: 0;
	position: static;
	border: none;
	background: #2a2e37;
    border-radius: 0;
  }

  .primary-menu-responsive .primary-menu-menu ul.sub-menu {
	position: relative;
  }

  .primary-menu-responsive .primary-menu-menu > li {
	padding: 0;
  }

  /* Dropdown items */
  .primary-menu-responsive .primary-menu-menu ul.sub-menu li{
	position: relative;
    padding: 0;
  }

  .primary-menu-responsive .primary-menu-menu ul.sub-menu li a {
	padding: 10px 30px;
	font-size: 14px;
    border-bottom: solid 1px rgba(255, 186, 0, 0.1);
    position: relative;
  }

  .primary-menu-responsive .primary-menu-menu ul.sub-menu.sub-menu--with-icons li .icon-arrow-right-line {
    display: none;
  }

  .primary-menu-responsive .primary-menu-menu ul.sub-menu.sub-menu--with-icons {
    padding-bottom: 0;
  }

  .primary-menu-responsive .primary-menu-menu ul.sub-menu.sub-menu--with-icons li:last-child a {
    border-bottom: solid 1px rgba($primary-color,.1);
  }

  .primary-menu-responsive .primary-menu-menu.primary-menu-indented > li > ul.sub-menu > li > ul.sub-menu > li > ul.sub-menu > li > a{ padding-left: 80px !important; }
  .primary-menu-responsive .primary-menu-menu.primary-menu-indented > li > ul.sub-menu > li > ul.sub-menu > li > ul.sub-menu > li:hover > a{ padding-left: 90px !important; }

  .primary-menu-responsive .primary-menu-menu ul.sub-menu li.menu-item-has-children .sub-menu a {
    padding-left: 40px;
  }

  .primary-menu-responsive .product-item-content .title {
    display: none;
  }

  /* Dropdown/megamenu indicators */
  .primary-menu-responsive .primary-menu-menu li .indicator{
	width: 60px;
	height: 100%;
	position: absolute;
	right: 0;
	top: 0;
	font-size: 14px;
	text-align: center;
	line-height: 53px;
    border-left: solid 1px rgba(255, 186, 0, .1);

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border: 5px solid transparent;
      border-top: 5px solid rgba(255, 186, 0, 1);
    }
  }
  .primary-menu-responsive .primary-menu-menu li ul.sub-menu li .indicator{
	height: 100%;
	right: 0;
	top: -3px;
	border-left: none;

    &:after {
      transform: rotate(-90deg);
    }
  }

  /* Megamenu */
  .primary-menu-responsive .primary-menu-menu > li > .megamenu{
	width: 100% !important;
	left: 0 !important;
	position: static;
	border-top: none;
	box-shadow: none;
    border-radius: 0;
  }
  .primary-menu-responsive .primary-menu-menu > li > .megamenu .megamenu-row [class*="col"],
  nav.primary-menu-responsive li.menu-item-has-mega-menu ul.mega-menu-row li.mega-menu-col {
	float: none;
	display: block;
	width: 100% !important;
	margin-left: 0;
	margin-top: 0;
  }
  .primary-menu-responsive .primary-menu-menu > li > .megamenu .megamenu-row:first-child  [class*="col"]:first-child,
  nav.primary-menu-responsive li.menu-item-has-mega-menu ul.mega-menu-row li.mega-menu-col:first-child {
	margin-top: 0;
  }

  .primary-menu-responsive .primary-menu-menu > li > .megamenu .megamenu-row{
	margin-top: 0;
  }

  .primary-menu-responsive .primary-menu-menu > li.menu-item-has-mega-menu .megamenu ul > li a {
	font-size: 14px;
  }

  /* Search field */
  .primary-menu-responsive .primary-menu-menu > li.search form{
	width: 100%;
	margin: 4px 0;
	padding: 10px 16px !important;
  }
  .primary-menu-responsive .primary-menu-menu > li.search form input[type="text"]{
	width: 100%;
  }

  /* Tabs */
  .primary-menu-responsive .primary-menu-tabs-nav{
	width: 100%;
  }
  .primary-menu-responsive .primary-menu-tabs-content{
	width: 100%;
  }
}

/* CSS3 ANIMATIONS */

.zoom-in{
  -webkit-animation: zoomIn 400ms ease both;
  -moz-animation: zoomIn 400ms ease both;
  -o-animation: zoomIn 400ms ease both;
  animation: zoomIn 400ms ease both;
}
@-webkit-keyframes zoomIn {
  0% { -webkit-transform: scale(1.5); }
  100% { -webkit-transform: scale(1); }
}
@-moz-keyframes zoomIn {
  0% { -moz-transform: scale(1.5); }
  100% { -moz-transform: scale(1); }
}
@-o-keyframes zoomIn {
  0% { opacity: 0; -o-transform: scale(1.5); }
  100% { opacity: 1; -o-transform: scale(1); }
}
@keyframes zoomIn {
  0% { transform: scale(1.5); }
  100% { transform: scale(1); }
}
.zoom-out{
  -webkit-animation: zoomOut 400ms ease both;
  -moz-animation: zoomOut 400ms ease both;
  -o-animation: zoomOut 400ms ease both;
  animation: zoomOut 400ms ease both;
}
@-webkit-keyframes zoomOut {
  0% { -webkit-transform: scale(.6); }
  100% { -webkit-transform: scale(1); }
}
@-moz-keyframes zoomOut {
  0% { -moz-transform: scale(.6); }
  100% { -moz-transform: scale(1); }
}
@-o-keyframes zoomOut {
  0% { -o-transform: scale(.6); }
  100% { -o-transform: scale(1); }
}
@keyframes zoomOut {
  0% { transform: scale(.6); }
  100% { transform: scale(1); }
}
.drop-up{
  -webkit-animation: drop-up 400ms ease both;
  -moz-animation: drop-up 400ms ease both;
  -o-animation: drop-up 400ms ease both;
  animation: drop-up 400ms ease both;
}
@-webkit-keyframes drop-up {
  0% { -webkit-transform: translateY(100px); }
  100% { -webkit-transform: translateY(0); }
}
@-moz-keyframes drop-up {
  0% { -moz-transform: translateY(100px); }
  100% { -moz-transform: translateY(0); }
}
@-o-keyframes drop-up {
  0% { -o-transform: translateY(100px); }
  100% { -o-transform: translateY(0); }
}
@keyframes drop-up {
  0% { transform: translateY(100px); }
  100% { transform: translateY(0); }
}
.drop-left{
  -webkit-animation: drop-left 400ms ease both;
  -moz-animation: drop-left 400ms ease both;
  -o-animation: drop-left 400ms ease both;
  animation: drop-left 400ms ease both;
}
@-webkit-keyframes drop-left {
  0% { -webkit-transform: translateX(-100px); }
  100% { -webkit-transform: translateX(0); }
}
@-moz-keyframes drop-left {
  0% { -moz-transform: translateX(-100px); }
  100% { -moz-transform: translateX(0); }
}
@-o-keyframes drop-left {
  0% { -o-transform: translateX(-100px); }
  100% { -o-transform: translateX(0); }
}
@keyframes drop-left {
  0% { transform: translateX(-100px); }
  100% { transform: translateX(0); }
}
.swing {
  -webkit-transform-origin: top center;
  -moz-transform-origin: top center;
  -o-transform-origin: top center;
  transform-origin: top center;
  -webkit-animation: swing 400ms ease-out both;
  -moz-animation: swing 400ms ease-out both;
  -o-animation: swing 400ms ease-out both;
  animation: swing 400ms ease-out both;
}
@-webkit-keyframes swing {
  20%, 40%, 60%, 80%, 100% { -webkit-transform-origin: top center; }
  20% { -webkit-transform: rotate(15deg); }
  40% { -webkit-transform: rotate(-10deg); }
  60% { -webkit-transform: rotate(5deg); }
  80% { -webkit-transform: rotate(-5deg); }
  100% { -webkit-transform: rotate(0deg); }
}
@-moz-keyframes swing {
  20% { -moz-transform: rotate(15deg); }
  40% { -moz-transform: rotate(-10deg); }
  60% { -moz-transform: rotate(5deg); }
  80% { -moz-transform: rotate(-5deg); }
  100% { -moz-transform: rotate(0deg); }
}
@-o-keyframes swing {
  20% { -o-transform: rotate(15deg); }
  40% { -o-transform: rotate(-10deg); }
  60% { -o-transform: rotate(5deg); }
  80% { -o-transform: rotate(-5deg); }
  100% { -o-transform: rotate(0deg); }
}
@keyframes swing {
  20% { transform: rotate(15deg); }
  40% { transform: rotate(-10deg); }
  60% { transform: rotate(5deg); }
  80% { transform: rotate(-5deg); }
  100% { transform: rotate(0deg); }
}

.flip{
  -webkit-animation: flip 700ms ease both;
  -moz-animation: flip 700ms ease both;
  -o-animation: flip 700ms ease both;
  animation: flip 700ms ease both;
}
@-webkit-keyframes flip {
  0% {
	-webkit-transform: perspective(400px) rotateX(90deg);
	transform: perspective(400px) rotateX(90deg);
	opacity: 0;
  }

  40% {
	-webkit-transform: perspective(400px) rotateX(-10deg);
	transform: perspective(400px) rotateX(-10deg);
  }

  70% {
	-webkit-transform: perspective(400px) rotateX(10deg);
	transform: perspective(400px) rotateX(10deg);
  }

  100% {
	-webkit-transform: perspective(400px) rotateX(0deg);
	transform: perspective(400px) rotateX(0deg);
	opacity: 1;
  }
}

@keyframes flip {
  0% {
	-webkit-transform: perspective(400px) rotateX(90deg);
	-ms-transform: perspective(400px) rotateX(90deg);
	transform: perspective(400px) rotateX(90deg);
	opacity: 0;
  }

  40% {
	-webkit-transform: perspective(400px) rotateX(-10deg);
	-ms-transform: perspective(400px) rotateX(-10deg);
	transform: perspective(400px) rotateX(-10deg);
  }

  70% {
	-webkit-transform: perspective(400px) rotateX(10deg);
	-ms-transform: perspective(400px) rotateX(10deg);
	transform: perspective(400px) rotateX(10deg);
  }

  100% {
	-webkit-transform: perspective(400px) rotateX(0deg);
	-ms-transform: perspective(400px) rotateX(0deg);
	transform: perspective(400px) rotateX(0deg);
	opacity: 1;
  }
}
.roll-in{
  -webkit-animation: roll-in 400ms ease both;
  -moz-animation: roll-in 400ms ease both;
  -o-animation: roll-in 400ms ease both;
  animation: roll-in 400ms ease both;
}
@-webkit-keyframes roll-in {
  0% {
	opacity: 0;
	-webkit-transform: translateX(-100%) rotate(-15deg);
	transform: translateX(-100%) rotate(-15deg);
  }

  100% {
	opacity: 1;
	-webkit-transform: translateX(0px) rotate(0deg);
	transform: translateX(0px) rotate(0deg);
  }
}
@keyframes roll-in {
  0% {
	opacity: 0;
	-webkit-transform: translateX(-100%) rotate(-15deg);
	-ms-transform: translateX(-100%) rotate(-15deg);
	transform: translateX(-100%) rotate(-15deg);
  }

  100% {
	opacity: 1;
	-webkit-transform: translateX(0px) rotate(0deg);
	-ms-transform: translateX(0px) rotate(0deg);
	transform: translateX(0px) rotate(0deg);
  }
}
.stretch{
  -webkit-animation: stretch 500ms ease both;
  -moz-animation: stretch 500ms ease both;
  -o-animation: stretch 500ms ease both;
  animation: stretch 500ms ease both;
  transform-origin: 0% 0%;
  -ms-transform-origin: 0% 0%;
  -webkit-transform-origin: 0% 0%;
}
@keyframes stretch{
  0% { transform: scaleX(0.3); }
  40% { transform: scaleX(1.02); }
  60% { transform: scaleX(0.98); }
  80% { transform: scaleX(1.01); }
  100% { transform: scaleX(0.98); }
  80% { transform: scaleX(1.01); }
  100% { transform: scaleX(1); }
}
@-webkit-keyframes stretch{
  0% { -webkit-transform: scaleX(0.3); }
  40% { -webkit-transform: scaleX(1.02); }
  60% { -webkit-transform: scaleX(0.98); }
  80% { -webkit-transform: scaleX(1.01); }
  100% { -webkit-transform: scaleX(0.98); }
  80% { -webkit-transform: scaleX(1.01); }
  100% { -webkit-transform: scaleX(1); }
}
@-o-keyframes stretch{
  0% { -o-transform: scaleX(0.3); }
  40% { -o-transform: scaleX(1.02); }
  60% { -o-transform: scaleX(0.98); }
  80% { -o-transform: scaleX(1.01); }
  100% { -o-transform: scaleX(0.98); }
  80% { -o-transform: scaleX(1.01); }
  100% { -o-transform: scaleX(1); }
}

nav li.menu-item-has-mega-menu:hover {
  position: static;
}

nav li.menu-item-has-mega-menu ul.mega-menu-row,
nav li.menu-item-has-mega-menu ul.mega-menu-row ul {
  position: static !important;
  margin: 0 !important;
  width: 100%;
  display: table !important;
  table-layout: fixed;
  padding: 0;
  float: none;
  left: 0 !important;
  opacity: 1 !important;
  visibility: visible !important;;
  transform: none !important;
  -webkit-transform: none !important;
}

.mega-menu-col {
  display: table-cell;
  width: 1px;
}

nav li.menu-item-has-mega-menu ul.mega-menu-row .mega-menu-col ul {
  display: block !important;
  width: 100%;
}

nav li.menu-item-has-mega-menu ul.mega-menu-row li {
  padding: 0 !important;
}

.product-item {
  padding: 35px 20px;
  min-height: 100%;
  transition: all .3s ease;
}