/*------------- #TABLE OF CONTENTS --------------*/

/*------------------------------------------------------------------

1. Blocks / Styles for Blocks
	1.1 Accordion
	1.2 Alerts messages
	1.3 Blogs
	1.4 Buttons
	1.5 Chart statistics
	1.6 Checkboxes
	1.7 Circled block
	1.8 Clients
	1.9 Comments
	1.10 Contacts
	1.11 Countdown
	1.12 Counters
	1.13 Crypto converter
	1.14 Events
	1.15 Experts
	1.16 Featured block
	1.17 Forms
	1.18 Headings
	1.19 Info boxes
	1.20 Lessons
	1.21 Move Background
	1.22 Navigation
	1.23 Pie charts
	1.24 Popup windows
	1.25 Pricing tables
	1.26 Quotes
	1.27 Radio buttons
	1.28 Skills
	1.29 Sliders
	1.30 Socials
	1.31 Tables
	1.32 Testimonials
	1.33 Range Sliders

2. Plugins / Styles for plugins
	2.1 Datepicker
	2.3 Custom scrollBar
	2.4 Select2
	2.5 Swiper
	2.6 Time Circles
	2.7 Ion Range Slider

3. Theme Styles / Main Theme Styles
	3.1 Footer
	3.2 Global
	3.3 Grid
	3.4 Header
	3.5 Helper
	3.6 Magnific popup
	3.7 Mixins
	3.8 Normalize
	3.9 Page 404
	3.10 Preloader
	3.11 Primary menu
	3.12 Tabs
	3.13 Typography
	3.14 Variables

4. Widgets / Styles for all Widgets
	4.1 Widget Archives
	4.2 Widget Distribution Ends
	4.3 Widget Help
	4.4 Widget Info
	4.5 Widget Latest News
	4.6 Widget Subscribe
	4.7 Widget Tags
	4.8 Widget Upcoming Events



-------------------------------------------------------------------*/